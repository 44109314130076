import logo from './logo.svg';
import './App.css';
import { Link } from 'react-scroll';
import projects from './projects.json';


function WelcomeSection() {
  return (
    <header id="main-header" className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <h1>deos.work</h1>
      <p>it`s just like you read</p>
      <p>it`s deos work</p>
      <Link to="Project-gallery" smooth={true} duration={1000} className="App-link">Browse my projects</Link>

    </header>
  );
}

function TopNav() {
  return (
    <div className="Top-nav">
      <div className="caption">
        <h1>deos.work</h1>
        <img src={logo} className="App-logo-small" alt="logo" />
        <p>it`s just like you read</p>
      </div>
      <div className="Top-nav-navigation">
        <nav className="Top-nav-menu">
          <ul>
            <li><Link to="main-header" smooth={true} duration={1000} className="App-link">Home</Link></li>
            <li><Link to="Project-gallery" smooth={true} duration={1000} className="App-link">Projects</Link></li>
            <li><Link to="contact" smooth={true} duration={1000} className="App-link">Contact</Link></li>
          </ul>
        </nav>
      </div>
    </div>
  );

}

function ProjectImage({ title = "Title", description, liveLink, repoLink, imageLink = logo }) {
  return (
    <div className="Gallery-element">
      <div className="Gallery-element-header">
        <h2>{title}</h2>
      </div>
      <div className="Gallery-element-content">
        <img src={imageLink} alt="logo" />
        <p>{description}</p>
      </div>
      <div className="Gallery-element-footer">
      <a href={liveLink} target="blank" className="App-link">Live demo</a>
        {repoLink
        ? <a href={repoLink} target="blank" className="App-link">View on github</a>
        : null
}

      </div>
    </div>
  );

}

function ProjectGallery() {
  return (
    <section id="projects" className="Project-gallery">
      <div className="Project-gallery-grid">
        {projects.map((project, index) => (
          <ProjectImage
            key={index}
            title={project.title}
            description={project.description}
            liveLink={project.liveLink}
            repoLink={project.repoLink}
            imageLink={project.imageLink} />
        ))}
      </div>
    </section>
  );

}


function ContactSection() {
  return (
    <section id="contact" className="Contact-section">
      <div className="Right-social-media">
        <a href="https://www.linkedin.com/in/dawid-osiecki-b8284858/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
        <a href="https://www.facebook.com/dawid.osiecki.50" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
        <a href="https://github.com/dejvoss#welcome-" target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
        <a href="mailto:support@deos.work"><i className="fas fa-envelope"></i></a>
      </div>
    </section>
  );
}

function App() {
  return (
    <div className="App">
      <TopNav />
      <WelcomeSection />
      <ProjectGallery />
      <ContactSection />
    </div>
  );
}


export default App;